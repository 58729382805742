import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import "./Navbar.scss";
import { LanguageContext } from "../../App";
import { Backdrop, LinearProgress } from "@mui/material";
import { doc, getDoc } from "@firebase/firestore";
import { db } from "../../firebase";

const Navbar = ({ cacheUsers }) => {
  const navigate = useNavigate();
  const params = useParams();
  var currentLanguage = "";
  switch (params.language) {
    case "en-us":
    case "zh-tw":
    case "zh-cn":
      currentLanguage = params.language;
      break;
    default:
      currentLanguage = "en-us";
      break;
  }

  const [donorCount, setDonorCount] = useState(0);
  useEffect(() => {
    async function getDonorCount() {
      var docRef = doc(db, "count", "donor");

      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setDonorCount(docSnap.data().count);
      } else {
        setDonorCount(0);
      }
    }
    getDonorCount();
  }, []);

  const [openPopup, setOpenPopup] = useState("none");
  const [allCacheUsers, setAllCacheUsers] = useState([]);
  const [randomSeed, setRandomSeed] = useState(0);
  const languageContext = useContext(LanguageContext);

  const language = {
    donation_title: {
      "en-us": "I need your help! ",
      "zh-cn": "我需要你的帮助！ ",
      "zh-tw": "我需要你的幫助！ ",
    },
    donation_subtitle: {
      "en-us":
        "As a sole developer, I am reaching out to seek your support.<br><br>" +
        "If you're an exceptional (less than 0.1% of total) user who has already donated, I sincerely thank you!<br><br>" +
        "Please Donate! (☉д⊙)",
      "zh-cn":
        "身为唯一的开发者，我正在寻求您的宝贵支持，以维持 HSR Statistics网站。<br><br>" +
        "如果您是已经捐款的用户(少于0.1%的总人数)，我衷心感谢您！<br><br>" +
        "请捐赠! (☉д⊙)",
      "zh-tw":
        "身為唯一的開發者，我正在尋求您的寶貴支持，以維持 HSR Statistics網站。<br><br>" +
        "如果您是已經捐款的用戶(少于0.1%的总人数)，我衷心感謝您！<br><br>" +
        "請捐贈！(☉д⊙)",
    },
    donor: {
      "en-us": " USD achieved!",
      "zh-cn": " 美金达成！",
      "zh-tw": " 美金達成！",
    },
    disclaimer_first: {
      "en-us": "Welcome for discussion and feature suggestion. Join our ",
      "zh-cn": "欢迎来讨论和建议新功能。现在加入我们的 ",
      "zh-tw": "歡迎來討論和建議新功能。現在加入我們的 ",
    },
    disclaimer_second: {
      "en-us": "Server.",
      "zh-cn": "伺服。",
      "zh-tw": "伺服。",
    },
    ranking: {
      "en-us": "Ranking",
      "zh-cn": "排行榜",
      "zh-tw": "排行榜",
    },
    relic: {
      "en-us": "Relic",
      "zh-cn": "遗器",
      "zh-tw": "遺器",
    },
    relic_scorer: {
      "zh-cn": "遗器计分器",
      "zh-tw": "遺器計分器",
      "en-us": "Relic Scorer",
    },
    character: {
      "en-us": "Character",
      "zh-cn": "角色",
      "zh-tw": "角色",
    },
    disclaimer: {
      "en-us": "Disclaimer",
      "zh-cn": "免责声明",
      "zh-tw": "免責聲明",
    },
    close: {
      "en-us": "Close",
      "zh-cn": "关闭",
      "zh-tw": "關閉",
    },
  };

  const rankingList = [
    {
      id: "ranking/relic",
      "en-us": "Relic",
      "zh-cn": "遗器",
      "zh-tw": "遺器",
    },
    {
      id: "ranking/character",
      "en-us": "Character",
      "zh-cn": "角色",
      "zh-tw": "角色",
    },
  ];

  const languageList = [
    {
      id: "en-us",
      name: "English",
      shorten: "ENG",
    },
    {
      id: "zh-cn",
      name: "简体中文",
      shorten: "简中",
    },
    {
      id: "zh-tw",
      name: "繁體中文",
      shorten: "繁中",
    },
  ];
  useEffect(() => {
    if (localStorage.getItem("profiles") !== "undefined") {
      var storedProfiles = JSON.parse(localStorage.getItem("profiles"));

      if (storedProfiles === null) storedProfiles = [];

      var cacheProfiles = [];
      for (let i = 0; i < storedProfiles.length; i++) {
        cacheProfiles.push({
          uid: storedProfiles[i].split(" ")[0],
          name: storedProfiles[i].split(" ")[1],
        });
      }

      setAllCacheUsers(cacheProfiles);
    }

    if (
      languageContext.language !== "" &&
      languageContext.language !== currentLanguage &&
      typeof currentLanguage !== "undefined"
    ) {
      languageContext.setLanguage(
        languageList.filter((id) => id.id === currentLanguage)[0].id
      );
      languageContext.setShorten(
        languageList.filter((id) => id.id === currentLanguage)[0].shorten
      );
      localStorage.setItem(
        "language",
        languageList.filter((id) => id.id === currentLanguage)[0].id
      );
    }
  }, [cacheUsers, params]);

  function openMenu(property_menu) {
    const mainMenu = document.getElementById(property_menu);
    var visibility = mainMenu.style.visibility;
    var oppositeVisibility = "collapse";
    if (visibility === "collapse" || visibility === "")
      oppositeVisibility = "visible";

    mainMenu.style.visibility = oppositeVisibility;
  }

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    var cacheTime = localStorage.getItem("donation_refresh_time");
    if (cacheTime === null) {
      localStorage.setItem("donation_refresh_time", new Date().getTime());
      setOpenDialog(true);
    } else {
      var currentTime = new Date().getTime();
      // 1 Hour
      if ((currentTime - cacheTime) / 1000 >= 3600) {
        const premiumUsers = [
          "600900400", // Violyre 3 USD - Patreon - 2023-10-28
          "614364694", // Paul Tran 3 USD - Patreon - 2023-11-10
          "703507866", // Lazyrea 5 USD - Paypal - 2023-12-08
          "804546623", // LiG_Satawut 1 USD - Patreon - 2023-11-02
          "803964415", // Nemuri
          "601419922", // Nightshade Discord 1 USD - 2024-01-03
          "600746802", // Sky 3 USD - 2024-01-24
          "804697599", // Bawk 1 USD - 2024-01-18
          "613662468", // TJ Turtle 3 USD - Patreon - 2024-03-13
          "600356570", // Reiella 10 USD - Patreon - 2024-03-04
          "616583140", // James 5 USD - Paypal - 2024-03-20
          "600900695", // Christopher 2 USD  - Paypal - 2024-03-10
          "701766087", // Ihor Sokol 10 USD  - Paypal - 2024-03-29
          "602307043", // Eric Nguyen 20 USD - Paypal - 2024-03-29
          "603925118", // Deathmatch 10 USD - Patreon - 2024-05-24
          "715805265", // Maribel Camilo 10 USD - Patreon - 2024-07-01
          "716578072", // Christian Nwankwo 10 USD - Paypal - 2024-07-11
          "811541256", // Raven 3 USD - Patreon - 2024-07-30
          "701825771", // Samantha 1 USD - Paypal - 2024-08-19
        ];
        var premiumUser = false;
        if (
          localStorage.getItem("profiles") !== "undefined" &&
          localStorage.getItem("profiles") !== null
        ) {
          var storedProfiles = JSON.parse(localStorage.getItem("profiles"));

          for (let i = 0; i < storedProfiles.length; i++) {
            if (
              premiumUsers.includes(String(storedProfiles[i]).split(" ")[0])
            ) {
              console.log(
                "Premium User " + String(storedProfiles[i]).split(" ")[0]
              );
              premiumUser = true;
            }
          }
        }

        if (premiumUser === false) {
          localStorage.setItem("donation_refresh_time", new Date().getTime());
          setOpenDialog(true);
        }
      }
    }
  }, []);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="homeNavbar">
      <div className="top">
        <div>
          {languageContext.language !== "" ? (
            <Link
              to={"/" + languageContext.language + "/"}
              style={{ textDecoration: "none" }}
            >
              <div className="topLeft">
                <img className="logo" alt="" />
              </div>
            </Link>
          ) : (
            <Link to={"/en-us/"} style={{ textDecoration: "none" }}>
              <div className="topLeft">
                <img className="logo" alt="" />
              </div>
            </Link>
          )}
        </div>
        <div className="hamburger">
          <div className="mainmenu" id="language_menu">
            <ul className="innerMenu">
              {languageList.map((item, i) => {
                return (
                  <li
                    key={item.id}
                    onClick={() => {
                      if (item.id === languageContext.language) {
                        return;
                      }

                      openMenu("language_menu");
                      languageContext.setLanguage(item.id);
                      languageContext.setShorten(item.shorten);
                      localStorage.setItem("language", item.id);

                      var currentLocation = window.location.href.slice(
                        window.location.href.indexOf(
                          "/" + languageContext.language
                        ) + 6
                      );
                      if (
                        currentLocation !== "/" &&
                        currentLocation !== "//localhost:3000/" &&
                        String(currentLocation).endsWith(
                          "hsr-statistics.com/"
                        ) === false
                      ) {
                        var changedLocation = "/" + item.id + currentLocation;
                        navigate(changedLocation);
                      } else {
                        var changedLocation = "/" + item.id + "/";
                        navigate(changedLocation);
                      }
                    }}
                  >
                    {item.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <p
            className="sectionButton language"
            onClick={() => {
              openMenu("language_menu");
            }}
          >
            <img
              className="logo"
              src={require("../../components/image/icon/language.png")}
              alt=""
            />
            {languageContext.shorten}
          </p>
          <Link
            to="https://www.patreon.com/JackyTan"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <p className="sectionButton">Patreon</p>
          </Link>
          <div className="icon">
            <MenuIcon
              onClick={() => {
                if (openPopup === "none") {
                  setOpenPopup("block");
                } else {
                  setOpenPopup("none");
                }
              }}
            ></MenuIcon>
          </div>
        </div>
        <div className="topRight">
          <div className="mainmenu" id="language_main_menu">
            <ul className="innerMenu">
              {languageList.map((item, i) => {
                return (
                  <li
                    key={item.id}
                    onClick={() => {
                      if (item.id === languageContext.language) {
                        return;
                      }

                      openMenu("language_main_menu");
                      languageContext.setLanguage(item.id);
                      languageContext.setShorten(item.shorten);
                      localStorage.setItem("language", item.id);

                      var currentLocation = window.location.href.slice(
                        window.location.href.indexOf(
                          "/" + languageContext.language
                        ) + 6
                      );
                      if (
                        currentLocation !== "/" &&
                        currentLocation !== "//localhost:3000/" &&
                        String(currentLocation).endsWith(
                          "hsr-statistics.com/"
                        ) === false
                      ) {
                        var changedLocation = "/" + item.id + currentLocation;
                        navigate(changedLocation);
                      } else {
                        var changedLocation = "/" + item.id + "/";
                        navigate(changedLocation);
                      }
                    }}
                  >
                    {item.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <p
            className="sectionButton language"
            onClick={() => {
              openMenu("language_main_menu");
            }}
          >
            <img
              className="logo"
              src={require("../../components/image/icon/language.png")}
              alt=""
            />
            {languageContext.shorten}
          </p>

          <div className="mainmenu" id="ranking_main_menu">
            <ul className="innerMenu">
              {rankingList.map((item, i) => {
                return (
                  <li
                    key={item.id}
                    onClick={() => {
                      navigate("/" + languageContext.language + "/" + item.id);
                      openMenu("ranking_main_menu");
                    }}
                  >
                    {item[languageContext.language]}
                  </li>
                );
              })}
            </ul>
          </div>
          <p
            className="sectionButton language"
            onClick={() => {
              openMenu("ranking_main_menu");
            }}
          >
            {language.ranking[languageContext.language]}
          </p>
          <Link
            to={"/" + languageContext.language + "/relic-scorer"}
            style={{ textDecoration: "none" }}
          >
            <p className="sectionButton">
              {language.relic_scorer[languageContext.language]}
            </p>
          </Link>
          <Link
            to={"/" + languageContext.language + "/character"}
            style={{ textDecoration: "none" }}
          >
            <p className="sectionButton">
              {language.character[languageContext.language]}
            </p>
          </Link>
          <Link
            to={"/" + languageContext.language + "/disclaimer"}
            style={{ textDecoration: "none" }}
          >
            <p className="sectionButton">
              {language.disclaimer[languageContext.language]}
            </p>
          </Link>
          <Link
            to="https://paypal.me/jackyxtan"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <p className="sectionButton">Paypal</p>
          </Link>
          <Link
            to="https://www.patreon.com/JackyTan"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <p className="sectionButton">Patreon</p>
          </Link>
          <Link
            to="https://discord.gg/aK6cJZ9hnQ"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <p className="sectionButton">Discord</p>
          </Link>
        </div>
      </div>
      <div className="disclaimer">
        <div className="cache">
          {allCacheUsers.length > 0 ? (
            <div className="cacheSections">
              {allCacheUsers.map((item, i) => {
                return (
                  <div key={item.uid}>
                    <div>
                      <div className="cacheUsers">
                        <Link
                          to={
                            "/" +
                            languageContext.language +
                            "/profile/" +
                            item.uid
                          }
                          style={{ textDecoration: "none" }}
                        >
                          <h4>
                            {item.uid} | {item.name}
                          </h4>
                        </Link>
                        <span
                          className="closeButton"
                          onClick={() => {
                            var storedProfiles = [];
                            var cacheProfiles = [];

                            if (
                              localStorage.getItem("profiles") !== "undefined"
                            )
                              storedProfiles = JSON.parse(
                                localStorage.getItem("profiles")
                              );

                            if (storedProfiles === null) {
                              cacheProfiles = [];
                            } else {
                              var newStoredProflies = [];
                              for (let i = 0; i < storedProfiles.length; i++) {
                                if (
                                  storedProfiles[i].split(" ")[0] === item.uid
                                ) {
                                  continue;
                                }

                                newStoredProflies.push(storedProfiles[i]);

                                cacheProfiles.push({
                                  uid: storedProfiles[i].split(" ")[0],
                                  name: storedProfiles[i].split(" ")[1],
                                });
                              }
                            }

                            localStorage.setItem(
                              "profiles",
                              JSON.stringify(newStoredProflies)
                            );
                            setAllCacheUsers(cacheProfiles);
                          }}
                        >
                          <a className="cross">x</a>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="popUpContainer" style={{ display: openPopup }}>
        <div
          className="closeButton"
          onClick={() => {
            setOpenPopup("none");
          }}
        >
          <h4 style={{ fontSize: "30px" }}>X</h4>
        </div>
        <div className="details">
          <Link
            to={"/" + languageContext.language + "/relic-scorer"}
            onClick={() => {
              setOpenPopup("none");
            }}
            style={{ textDecoration: "none", color: "#0071bd" }}
          >
            <p className="sectionButton">
              {language.relic_scorer[languageContext.language]}
            </p>
          </Link>
          <Link
            to={"/" + languageContext.language + "/ranking/relic"}
            onClick={() => {
              setOpenPopup("none");
            }}
            style={{ textDecoration: "none", color: "#0071bd" }}
          >
            <p className="sectionButton">
              {language.relic[languageContext.language]}{" "}
              {language.ranking[languageContext.language]}{" "}
            </p>
          </Link>
          <Link
            to={"/" + languageContext.language + "/ranking/character"}
            onClick={() => {
              setOpenPopup("none");
            }}
            style={{ textDecoration: "none", color: "#0071bd" }}
          >
            <p className="sectionButton">
              {language.character[languageContext.language]}{" "}
              {language.ranking[languageContext.language]}{" "}
            </p>
          </Link>
          <Link
            to={"/" + languageContext.language + "/character"}
            onClick={() => {
              setOpenPopup("none");
            }}
            style={{ textDecoration: "none", color: "#0071bd" }}
          >
            <p className="sectionButton">
              {language.character[languageContext.language]}
            </p>
          </Link>
          <Link
            to={"/" + languageContext.language + "/disclaimer"}
            onClick={() => {
              setOpenPopup("none");
            }}
            style={{ textDecoration: "none", color: "#0071bd" }}
          >
            <p className="sectionButton">
              {language.disclaimer[languageContext.language]}
            </p>
          </Link>
          <Link
            to="https://paypal.me/jackyxtan"
            target="_blank"
            onClick={() => {
              setOpenPopup("none");
            }}
            style={{ textDecoration: "none" }}
          >
            <p className="sectionButton">Paypal</p>
          </Link>
          <Link
            to="https://www.patreon.com/JackyTan"
            target="_blank"
            onClick={() => {
              setOpenPopup("none");
            }}
            style={{ textDecoration: "none" }}
          >
            <p className="sectionButton">Patreon</p>
          </Link>
          <Link
            to="https://discord.gg/aK6cJZ9hnQ"
            target="_blank"
            onClick={() => {
              setOpenPopup("none");
            }}
            style={{ textDecoration: "none" }}
          >
            <p className="sectionButton">Discord</p>
          </Link>
          <p
            className="sectionButton"
            onClick={() => {
              setOpenPopup("none");
            }}
          >
            {language.close[languageContext.language]}
          </p>
        </div>
      </div>

      <Backdrop
        onClose={closeDialog}
        open={openDialog}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        style={{ background: "black", overflowY: "scroll" }}
      >
        <div style={{ marginTop: "10px", marginBottom: "auto" }}>
          <h2
            className="closeButton"
            style={{ textAlign: "right", width: "90%" }}
            onClick={() => {
              closeDialog();
            }}
          >
            x
          </h2>

          <h1
            style={{
              height: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {language.donation_title[currentLanguage]}
          </h1>

          <div className="buttons">
            <Link
              to="https://paypal.me/jackyxtan"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <h2
                className="button"
                style={{
                  height: "fit-content",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Paypal
              </h2>
            </Link>
            <Link
              to="https://www.patreon.com/JackyTan"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <h2
                className="button"
                style={{
                  height: "fit-content",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Patreon
              </h2>
            </Link>
          </div>
          <h4
            style={{
              textAlign: "left",
              height: "fit-content",
              margin: "10px",
              width: "60%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            dangerouslySetInnerHTML={{
              __html: language.donation_subtitle[currentLanguage],
            }}
          ></h4>
          <div className="progressBar">
            <LinearProgress
              value={(donorCount / 100) * 100}
              variant="determinate"
              style={{
                width: "60%",
                marginLeft: "auto",
                marginRight: "auto",
                height: "20px",
              }}
            />
            <h4>
              {donorCount + " / 100 "}
              {language.donor[currentLanguage]}{" "}
            </h4>
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default Navbar;
